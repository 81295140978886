import React, { createContext, ReactNode, useMemo, useContext } from 'react';
import { useErrandContext } from '@contexts/ErrandContext';
import { IParticipant } from '@interfaces/Conversation';

type participantProviderProps = {
  children: ReactNode;
};

type participantValue = null | {
  allParticipants: IParticipant[];
  activeParticipants: IParticipant[];
  operatorParticipants: IParticipant[];
  userParticipants: IParticipant[];
  aiOperators: IParticipant[];
  humanOperators: IParticipant[];
  activeAIOperators: IParticipant[];
  activeAIOperatorsExcludingAngelAI: IParticipant[];
  inactiveAIOperators: IParticipant[];
  primaryUserParticipants: IParticipant[];
  secondaryUserParticipants: IParticipant[];
};

const ParticipantContext = createContext<participantValue | null>(null);

const useParticipantContext = () => {
  const participantContext = useContext(ParticipantContext);

  if (participantContext === undefined) {
    // throwing this error helps in development when the context is out of scope.
    throw new Error('useParticipantContext must be inside a ParticipantProvider');
  }

  return participantContext;
};

const ParticipantProvider = ({ children }: participantProviderProps): JSX.Element => {
  const { errand } = useErrandContext();

  const contextValue: participantValue = useMemo(() => {
    const output: participantValue = {
      allParticipants: errand?.participants || [],
      activeParticipants: [],
      operatorParticipants: [],
      userParticipants: [],
      aiOperators: [],
      humanOperators: [],
      activeAIOperators: [],
      inactiveAIOperators: [],
      primaryUserParticipants: [],
      secondaryUserParticipants: [],
      activeAIOperatorsExcludingAngelAI: [],
    };

    // Categorize participants in one pass
    output.activeParticipants = output.allParticipants.filter((x) => x?.active);

    output.activeParticipants.forEach((elm) => {
      const { userType, ai, muted, primary, userData } = elm;

      if (userType === 'Operator') {
        output.operatorParticipants.push(elm);
        if (ai) {
          output.aiOperators.push(elm);

          if (muted) {
            // muted
            output.inactiveAIOperators.push(elm);
          } else {
            // unmuted
            output.activeAIOperators.push(elm);

            const firstname = userData?.firstname;

            if (firstname !== 'AngelAi') {
              output.activeAIOperatorsExcludingAngelAI.push(elm);
            }
          }
        } else {
          output.humanOperators.push(elm);
        }
      } else if (userType === 'User') {
        output.userParticipants.push(elm);

        if (primary) {
          // primary user
          output.primaryUserParticipants.push(elm);
        } else {
          // secondary user or invited
          output.secondaryUserParticipants.push(elm);
        }
      } else {
        console.error(`Unexpected userType: |${userType}|`);
      }
    });

    return output;
  }, [errand?.participants]);

  return <ParticipantContext.Provider value={contextValue}>{children}</ParticipantContext.Provider>;
};

export { ParticipantProvider, useParticipantContext };
