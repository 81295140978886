import { ValidatorFunctions } from '@common/Validators';
import { IErrand, IParticipant } from '@interfaces/Conversation';
import { getOperatorId } from '@storage/operatorStorage';
import { IHandlerFunc, IProcedure } from './interfaces';
import { ChatType } from '@common/ChatType';

const { isUndefinedOrNull } = ValidatorFunctions;

const DEBUG_DEPENDENCY_PROPS = false;

class notInHistoryChat implements IProcedure {
  public runChecks(status: string, type: string): boolean {
    // history chat (closed or inactive) and (not team and not group)
    if ((status === 'inactive' || status === 'closed') && type !== ChatType.Team && type !== ChatType.Group) {
      return false;
    }

    // not history chat
    return true;
  }

  public process(props: any): boolean {
    // check for status to be "active"
    const errandStatus = props.errand.status;
    const errandType = props.errand.type;

    // run checks and return result
    const success = this.runChecks(errandStatus, errandType);

    return success;
  }
}

type IsOperatorParticipantProps = {
  errand: IErrand;
};
/**
 * This procedure takes care of checking the props for the operator being a participant of current chat.
 */
class IsOperatorParticipant implements IProcedure {
  public runChecks(participantIds: string[]): boolean {
    const currentOperatorID = getOperatorId();
    if (!Array.isArray(participantIds)) return false;
    // check if errand has current operator in its participant's array
    const isParticipant = participantIds.includes(currentOperatorID);
    return isParticipant;
  }

  public process(props: IsOperatorParticipantProps): boolean {
    const participants = props.errand.participants;
    const participantIds = participants.map((participant: IParticipant) => participant.userData._id);
    // run needed checks on participantIds
    const success = this.runChecks(participantIds);
    // return result of those checks
    return success;
  }
}

/**
 * Overall Idea behind this HandlerPermissionsController is to determine whether the given Handler is permitted to run OR not (meaning it would return empty function OR original function in case if all checks return true)
 */
class HandlerPermissionsController {
  private messages = {
    notPermitted: 'Handler is not permitted to execute.',
    filterError: 'Error occured during "filter" method execution',
  };

  private procedures: IProcedure[] | [];
  private dependencyProps: string[] | [];

  public constructor(procedures: IProcedure[] | [] = [], dependencyProps: string[] = []) {
    this.procedures = procedures;
    this.dependencyProps = dependencyProps;
  }

  /**
   * @summary Main method for getting the Permitted Handler based on the provided props.
   */
  public filter(handler: IHandlerFunc, props: any): IHandlerFunc {
    try {
      // first of all, go through all dependencyProps
      // dependencyProps serve for anything that should be checked before processing procedures.
      // if dependencyProps fail, the original handler returns it means that this filter method was called in a wrong place
      if (this.dependencyProps.length > 0) {
        // check if any of the dependency prop is null or undefined.
        const propNullOrUndefinedResArr = this.dependencyProps.map((propKey) => {
          return isUndefinedOrNull(props[propKey]);
        });

        // some prop is undefined or null, return original handler, otherwise, proceed.
        if (propNullOrUndefinedResArr.includes(true)) {
          DEBUG_DEPENDENCY_PROPS &&
            console.warn(
              'Some dependency prop is null OR undefined, returning original handler.',
              JSON.stringify(this.dependencyProps)
            );
          return handler;
        }
      }

      let isPermitted = true;
      // There can be such things like role, level, tabs and etc. that would somehow change the logic flow due to the obvious reasons.
      // Thus, if any of such things change OR restrict any of the passed handler logic, correct transformed OR original function should be returned.
      // Pass the props to the given procedures (process method) that dictate whether the given handler should be tranformed/untouched/canceled.
      if (this.procedures.length === 0) {
        return () => {};
      }

      // iterate through the modules and run permission checks on given props
      for (const procedure of this.procedures) {
        if (procedure.process(props) === false) {
          isPermitted = false;
          break;
        }
      }

      // if not permitted, return the empty function that logs notPermitted message
      if (isPermitted === false) {
        return () => {
          console.log(this.messages.notPermitted);
        };
      }

      // handler is permitted to be executed, return it
      return handler; // Return original function
    } catch (error) {
      // Code to handle the error
      console.warn('An error occurred in HandlerPermissionsController.filter method: ', error);
      // return error msg log function
      return () => {
        console.warn(this.messages.filterError);
        console.warn(`Props Snapshot: ${JSON.stringify(props)}`);
      };
    }
  }
}

// all available Permission Controllers
// Operator handler permissions controller - consists of IsOperatorParticipant procedure as of now.
const OperatorHandlerPermissions = new HandlerPermissionsController(
  [new IsOperatorParticipant(), new notInHistoryChat()],
  ['operatorData']
);

export { OperatorHandlerPermissions };
