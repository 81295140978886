import React, { useRef, useState, useCallback, useEffect } from 'react';
import Styles from '@styles/TwinAvatarStyles.module.css';
import { ValidatorFunctions } from '@common/Validators';
import { useMessageContext } from '@contexts/MessageContext';
import { useAvatarContext } from '@contexts/avatar';
import axiosCall from '@services/axios';
import useAbortControllerV2 from '@common/hooks/useAbortControllerV2';

type TwinAvatarProps = {
  messageId: string;
};

const LogPrefix = (): string => {
  return `TwinAvatar ${(Date.now() % 1000000) / 1000}`;
};

const TwinAvatar = ({ messageId }: TwinAvatarProps) => {
  const { moveScrollToBottom } = useMessageContext();
  const { avatarState, requestAvatarOwnership, releaseAvatarOwnership } = useAvatarContext();

  const { getAbortSignal } = useAbortControllerV2();
  const videoRef = useRef<HTMLVideoElement>(null);

  const [grown, setGrown] = useState(false);

  const restartVideo = useCallback(() => {
    console.debug(`${LogPrefix()} video started!`);

    const video = videoRef.current;

    if (!video) return;

    // Reset video to the beginning
    video.currentTime = 0;
    video.muted = false;

    video.play().catch(() => {
      console.error('Unable to play avatar video, Maybe due to browser restriction?');
      video.muted = true;
      video.play().catch(() => console.error("Even failed to play when it's muted"));
    });
  }, []);

  const handleTransitionEnd = useCallback(
    (event) => {
      if (event.propertyName === 'height') {
        moveScrollToBottom();
        if (grown) {
          console.debug(`${LogPrefix()} grow end`);

          restartVideo();
        } else {
          console.debug(`${LogPrefix()} shrink end`);

          releaseAvatarOwnership(messageId);
        }
      }
    },
    [grown, restartVideo, moveScrollToBottom, messageId, releaseAvatarOwnership]
  );

  const handleVideoEnd = useCallback(() => {
    console.debug(`${LogPrefix()} handleVideoEnd`);
    setGrown(false);
  }, []);

  const handeEvent = useCallback(async () => {
    try {
      console.debug(`${LogPrefix()} handeEvent`);

      const config = getAbortSignal('handeEvent');

      // fetch video URL from Core
      const res = await axiosCall(
        {
          url: `twin/avatarUrl/${messageId}`,
        },
        config
      );

      const storageLocation = res?.storageLocation;

      if (ValidatorFunctions.isNotEmptyString(storageLocation)) {
        const video = videoRef.current;

        if (ValidatorFunctions.isNotUndefinedNorNull(video)) {
          // set video source to stream
          video.src = storageLocation;
          video.load();

          // add handler logic for start and finish
          video.onloadedmetadata = () => {
            console.debug(`${LogPrefix()} video loaded`);
            setGrown(true);
          };
        } else {
          throw new Error(`Video component ref not set yet`);
        }
      } else {
        throw new Error(`Unexpected storageLocation: |${storageLocation}|`);
      }
    } catch (err: any) {
      console.error(`TwinAvatar.tsx: handleEvent() something went wrong! |${err.message}|`);

      releaseAvatarOwnership(messageId);
    }
  }, [messageId, getAbortSignal, releaseAvatarOwnership]);

  useEffect(() => {
    console.debug(`${LogPrefix()} avatarState: |${avatarState}|`);

    if (requestAvatarOwnership(messageId)) {
      handeEvent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarState]);

  useEffect(() => {
    return () => {
      releaseAvatarOwnership(messageId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`${Styles.avatarContainer} ${grown ? Styles.growing : Styles.shrinking}`}
      onTransitionEnd={handleTransitionEnd}
    >
      <video ref={videoRef} className={Styles.video} onEnded={handleVideoEnd}>
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default TwinAvatar;
