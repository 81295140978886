/**
 * AvatarProvider Component
 *
 * This component provides context and manages the state of avatar ownership within the application.
 * It allows multiple child components to observe and request ownership of a video avatar.
 * The avatar can be owned by one message at a time, and only the current owner can release it.
 * The component handles avatar state transitions, including whether the avatar is active, idle, or in an error state.
 *
 * The context provides functions for:
 * 1. Requesting avatar ownership (`requestAvatarOwnership`).
 * 2. Releasing avatar ownership (`releaseAvatarOwnership`).
 * 3. Checking if a specific message is the current avatar owner (`isCurrentAvatarOwner`).
 * 4. Determining if the avatar should be active based on message and participant state (`isAvatarActive`).
 *
 * This provider ensures that only the appropriate participants can interact with the avatar
 * based on certain conditions such as message type, participant role, and morph type.
 */

import { useParticipantContext } from '@contexts/participant';
import { useUserContext } from '@contexts/user';
import { useErrandContext } from '@contexts/ErrandContext';

import { ValidatorFunctions } from '@common/Validators';
import { MorphType } from '@common/MorphType';
import React, { createContext, ReactNode, useCallback, useMemo, useContext, useRef, useState } from 'react';

const LogPrefix = (): string => {
  return `TwinAvatar ${(Date.now() % 1000000) / 1000}`;
};

enum AvatarState {
  Idle = 'Idle',
  Playing = 'Playing',
}

interface AvatarInternalDataType {
  currentOwnerId: string;
  playedAvatars: Set<string>;
}

type AvatarContextValueType = null | {
  avatarState: AvatarState;
  releaseAvatarOwnership: (messageId: string) => void;
  requestAvatarOwnership: (messageId: string) => boolean;
  checkIfCurrentMessageCanRenderAvatar: (
    messageId: string,
    isLastMessage: boolean,
    messageType: string,
    messageSenderId: string | undefined
  ) => boolean;
};

type AvatarProviderProps = {
  children: ReactNode;
};

const AvatarContext = createContext<AvatarContextValueType | null>(null);

// Custom hook to access AvatarContext value
const useAvatarContext = () => {
  const avatarContext = useContext(AvatarContext);

  if (avatarContext === undefined) {
    // Error handling if the hook is called outside of AvatarProvider
    throw new Error('useAvatarContext must be inside a AvatarProvider');
  }

  return avatarContext;
};

// Set of message types that are excluded from acquiring avatar ownership
const excludedMessageTypes = new Set(['ProfileBubble', 'Disclaimer', 'WelcomeUser']);

const AvatarProvider = ({ children }: AvatarProviderProps): JSX.Element => {
  const { activeAIOperatorsExcludingAngelAI } = useParticipantContext();
  const { morphType } = useErrandContext();
  const { isOperator, _id } = useUserContext();

  const [avatarState, setAvatarState] = useState<AvatarState>(AvatarState.Idle);
  const internalDataRef = useRef<AvatarInternalDataType>({ currentOwnerId: '', playedAvatars: new Set<string>() });

  // Requests avatar ownership if no other owner is present
  const requestAvatarOwnership = useCallback(
    (messageId: string) => {
      const data = internalDataRef.current;

      if (data.currentOwnerId || avatarState !== AvatarState.Idle) return false;

      // keep track of played avatar
      if (data.playedAvatars.has(messageId)) return false;
      data.playedAvatars.add(messageId);

      console.debug(`${LogPrefix()} |${messageId}| requestAvatarOwnership`);
      internalDataRef.current.currentOwnerId = messageId;
      setAvatarState(AvatarState.Playing);

      return true;
    },
    [avatarState]
  );

  // Releases avatar ownership, ensuring only the current owner can release
  const releaseAvatarOwnership = useCallback((messageId: string): void => {
    if (internalDataRef.current.currentOwnerId !== messageId) {
      return;
    }

    console.debug(`${LogPrefix()} |${messageId}| releaseAvatarOwnership`);

    internalDataRef.current.currentOwnerId = '';
    setAvatarState(AvatarState.Idle);
  }, []);

  // Checks if the provided message ID is the current avatar owner
  const isCurrentAvatarOwner = useCallback((messageId: string): boolean => {
    return internalDataRef.current.currentOwnerId === messageId;
  }, []);

  // Determines whether the avatar should be displayed based on message context
  const isAvatarActive = useCallback(
    (isLastMessage: boolean, messageType: string, messageSenderId?: string): boolean => {
      // The avatar should only appear for the last message
      if (!isLastMessage) {
        return false;
      }

      // Ignore messages without a sender ID, user own message doesn't have messageSenderId
      if (messageSenderId === undefined) {
        return false;
      }

      // The avatar should not be used for messages sent by the current user
      if (_id === messageSenderId) {
        return false;
      }

      // Operators should not have an avatar
      if (isOperator) {
        return false;
      }

      // Avatar is only allowed if the morph type is 'None'
      if (morphType !== MorphType.None) {
        return false;
      }

      // Certain message types are excluded from having an avatar
      if (excludedMessageTypes.has(messageType)) {
        return false;
      }

      // Avatar is only allowed if at least one AI operator (excluding AngelAI) is active
      return ValidatorFunctions.isNotEmptyArray(activeAIOperatorsExcludingAngelAI);
    },
    [isOperator, activeAIOperatorsExcludingAngelAI, morphType, _id]
  );

  const checkIfCurrentMessageCanRenderAvatar = useCallback(
    (messageId: string, isLastMessage: boolean, messageType: string, messageSenderId: string | undefined): boolean => {
      return isCurrentAvatarOwner(messageId) || isAvatarActive(isLastMessage, messageType, messageSenderId);
    },
    [isCurrentAvatarOwner, isAvatarActive]
  );

  // Memoizing the context value to optimize re-renders
  const contextValue = useMemo(
    () => ({
      avatarState,
      requestAvatarOwnership,
      releaseAvatarOwnership,
      checkIfCurrentMessageCanRenderAvatar,
    }),
    [avatarState, requestAvatarOwnership, releaseAvatarOwnership, checkIfCurrentMessageCanRenderAvatar]
  );

  return <AvatarContext.Provider value={contextValue}>{children}</AvatarContext.Provider>;
};

export { AvatarProvider, useAvatarContext };
